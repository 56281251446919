@tailwind base;
@tailwind components;
@tailwind utilities;

/* PrimeReact styles */
@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

/* Custom PrimeReact styles */
.p-menuitem {
    padding: 5px 10px;
    background-color: black !important;
}
.p-menuitem-text {
    color: white !important;
}

.p-menuitem-icon {
    color: white !important;
}

.p-menuitem-link {
    color: white;
    background-color: black;
}

.p-submenu-list {
    background-color: black;
    border-radius: 4px;    
}

.p-menuitem-link:hover {
    background-color: #202020;
}

.p-menuitem-link .p-menuitem-text {
    color: white;
}


@media (max-width: 768px) {
    .p-menubar-root-list {
      flex-direction: column !important;
      align-items: start !important;
      width: 100% !important;
    }
  
    .p-menuitem-link {
      display: flex !important;
      align-items: center !important;
      padding: 1rem 1.5rem !important;
      font-size: 1.5rem !important;
    }
  
    .p-menuitem-icon {
      background-color: transparent !important;
      font-size: 1.5rem !important;
      /* margin-right: 0.75rem !important; */
    }
  
    .p-menuitem-text {
      display: inline !important;
      font-size: 1.25rem !important;
    }
  }